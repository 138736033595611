<template>
  <div>
    <template>
      <div>
        <div>
          <a @click="$refs.refInputEl.click()">
            <v-img max-height="150" contain :src="urlImg" aspect-ratio="1" class="grey lighten-2"></v-img>
            
          </a>
 
         
          <input ref="refInputEl"   @change="ChangeImg" type="file" accept=".jpeg,.png,.jpg,GIF" :hidden="true" />
          <v-btn x-small color="error" outlined class="mt-5" @click="resetImg()" v-if="urlImgDefault != urlImg" > Reset </v-btn>
          <p class="text-sm mt-5">
            Permitido JPG, GIF or PNG. Tamaño maximo 800K
            <br /> 
            <small v-if="requerido == true && urlImg == urlImgDefault" class="text-sm mt-5 error--text"
              >La imagen es requerida</small
            >
          </p>
        </div>

        <v-row>
          <v-col cols="3" v-for="(item, i) in imganes" :key="i">
            <v-img width="70" :src="item.url"></v-img>
          </v-col>
        </v-row>
        <br />
        <br />
      </div>
    </template>

    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Recortar imagen </v-card-title>

        <v-card-text>
          <v-row>
            <v-col lg="6">
              <croppa
                v-model="myCroppa"
                :width="recorteWidth"
                :height="recorteHeight"
                :placeholder="'Selecciona una imagen'"
                :placeholder-font-size="10"
                key=""
                :show-remove-button="false"
              >
                <img :width="recorteWidth" :height="recorteHeight"  crossOrigin="anonymous" :src="urlImg" slot="initial" />
              </croppa>
            </v-col>
            <v-col lg="6" class="pl-4">
              <ul v-if="!InfoMovil">
                <li>
                  <small>Arrastra y suelta un archivo</small>
                </li>
                <li>
                  <small> Haga clic para elegir un archivo</small>
                </li>
                <li>
                  <small> Arrastre para mover </small>
                </li>
                <li>
                  <small> Desplácese para ampliar </small>
                </li>
              </ul>
              <ul v-else>
                <li>
                  <small>Pestaña para elegir un archivo</small>
                </li>
                <li>
                  <small> Arrastre para mover </small>
                </li>
                <li>
                  <small> Pellizcar con dos dedos para hacer zoom </small>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="generateImage()"> Cortar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import store from '@/store'
export default {
  props: {
    rounded: Boolean,
    urlImgDefault: String,
    urlImgValue: String,
    requerido: Boolean,
    recorteWidth : {
      type: Number,
      default: 300
    },
    recorteHeight : {
      type: Number,
      default: 300
    },
    indRecortar : {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const dialog = ref(false)
    const notifications = ref(false)
    const sound = ref(false)
    const widgets = ref(false)
    const myCroppa = ref({})
    const InfoMovil = ref(false)
    const urlImg = ref(!props.urlImgValue ? props.urlImgDefault : props.urlImgValue)
    const ImgValidado = ref(!(props.requerido == true && urlImg.value == props.urlImgDefault))
    watch(urlImg, () => {
      ImgValidado.value = !(props.requerido == true && urlImg.value == props.urlImgDefault)
    })
    watch(props, () => {
      resetImg()
      urlImg.value = !props.urlImgValue ? props.urlImgDefault : props.urlImgValue
    })
    const datos = ref({
      imgInput: null,
      img: '',
    })
    onBeforeMount(() => {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        InfoMovil.value = true
      }
      resetImg()
    })

    const resetImg = () => {
      datos.value.imgInput = null
      urlImgSet()
    }
    const imganes = ref([])
    const ChangeImg2 = e => {
      const imagenes = []
      e.target.files.forEach((element, i) => {
        const url = URL.createObjectURL(element)
        imagenes.push({
          img: i,
          url,
        })
      })

      imganes.value = imagenes
    }

    const ChangeImg = e => {
      datos.value.imgInput = e.target.files[0]
      urlImgSet()
    }
    

    const generateImage = () => {
      let url = myCroppa.value.generateDataUrl()
      if (!url) { 
        return
      }
      urlImg.value = url
      dialog.value = false
    }

    const urlImgSet = () => {
      if (!datos.value.imgInput) {
        if (datos.value.img != '') {
          console.log(datos.value.img)
          console.log('imgane null')
          urlImg.value = `http://localhost:5001/empleado/img/${datos.value.img}`
        } else {
          console.log('imgane null')
          urlImg.value = props.urlImgDefault
        }
      } else {
        const imgs = URL.createObjectURL(datos.value.imgInput)
        console.log('imgane')
        urlImg.value = imgs
        const img_new = new Image()
        img_new.src = imgs
        console.log(img_new)
        myCroppa.value.initialImage = imgs
        console.log(myCroppa)
        if (props.indRecortar) {
          dialog.value = true
        }
    
      }
    }

    return {
      icons: {
        mdiClose,
      },
      dialog,
      notifications,
      sound,
      widgets,
      myCroppa,
      InfoMovil,
      urlImg,
      resetImg,
      ChangeImg,
      urlImgSet,
      generateImage,
      ImgValidado,
      datos,
      imganes,
      ChangeImg2,
    }
  },
}
</script>
